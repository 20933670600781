<template>
  <b-row>
    <b-col sm="12" md="3" class="mb-1">
      <b-list-group>
        <b-list-group-item class="font-weight-bold" :active="$route.name.includes('users-show')" :to="{name:'users-show'}"><feather-icon icon="UserIcon"></feather-icon> Personal Info</b-list-group-item>
        <b-list-group-item v-if="cP(17)" class="font-weight-bold" :active="$route.name.includes('users-password')" :to="{name:'users-password'}"><feather-icon icon="KeyIcon"></feather-icon> Ganti Password</b-list-group-item>
        <b-list-group-item v-if="cP(19)" class="font-weight-bold" :active="$route.name.includes('users-privilege')" :to="{name:'users-privilege'}"><feather-icon icon="CheckCircleIcon"></feather-icon> Privilege</b-list-group-item>
        <b-list-group-item v-if="cP(20)" class="font-weight-bold" :active="$route.name.includes('users-log')" :to="{name:'users-log'}"><feather-icon icon="ClockIcon"></feather-icon> Log User</b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col sm="12" md="9">
      <my-card>
        <template slot="body">
          <router-view />
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import {BListGroup,BListGroupItem} from 'bootstrap-vue'
export default {
  components:{
    BListGroup,
    BListGroupItem
  }
}
</script>

<style>

</style>